"use client"

import Link from "next/link";
import {SondageSvg, RrbLogoSvg} from "@/components/svg";
import {PlayerState} from "@/components/ui/player-state";
import {Button} from "@/components/ui/button";
import {IconMenu2, IconX} from "@tabler/icons-react";
import {Separator} from "@/components/ui/separator";
import AudioPlayerButton from "@/components/audioPlayer/AudioPlayButton";
import {ReactElement, useContext, useEffect, useState} from "react";
import AudioControlsBig from "@/components/audioPlayer/AudioControlsBig";
import {SocialLinkFb, SocialLinkX, SocialLinkYt} from "@/components/socialLinks/SocialLinks";
import {MeteoWidget} from "@/components/meteo";
import {useRouter} from "next/router";
import {MenuLinksContext} from "@/components/contexts";

export function Header(): ReactElement {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const {menuMainLinks} = useContext(MenuLinksContext);

  const router = useRouter();

  useEffect(() => {
    setMenuIsOpen(false);
  }, [router]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)')
    mediaQuery.addEventListener('change', checkIfMobileMenu)
    checkIfMobileMenu(mediaQuery)
  }, []);

  const toggleMenu = () => {
    setMenuIsOpen((prevState) => !prevState);
  }

  const checkIfMobileMenu = (e) => {
    setIsMobileMenu(e.matches);
  }

  useEffect(() => {
    if (menuIsOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [menuIsOpen]);

  return (
    <>
      <div className='bg-white py-[10px] max-md:px-4 px-8 flex justify-between items-center border-b'>
        <div className='flex items-center gap-3'>
          <Button variant='ghost' onClick={toggleMenu} className='text-foreground-dark py-2 px-4'
                  iconLeft={menuIsOpen ? <IconX/> : <IconMenu2/>}>
            <span className="hidden lg:inline">Menu</span>
          </Button>
          <Link href='/'>
            <RrbLogoSvg/>
          </Link>
          <span className="text-sm text-brand-mer-700 max-md:hidden">La fréquence aux couleurs de la France</span>
        </div>

        <div className='flex items-center gap-2'>
          <AudioPlayerButton className='gap-3'>
            <PlayerState isOnAir>En direct</PlayerState>
          </AudioPlayerButton>
          {/*<Button variant='ghost' iconRight={<IconUserCircle size='18' />}>*/}
          {/*    Mon compte*/}
          {/*</Button>*/}
          {/*<Separator orientation="vertical" className='h-6' />*/}
          {/*<Button variant='ghost' iconRight={<SondageSvg size='18' />}>*/}
          {/*    Mon avis*/}
          {/*</Button>*/}
        </div>
      </div>

      {
        menuIsOpen && (
          <div data-state={menuIsOpen ? 'open' : 'closed'}
               className="w-full h-[calc(100vh-73px)] lg:h-[calc(100vh-105px)] bg-light duration-700 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in">
            <div className="flex flex-1 h-full flex-col justify-between lg:flex-row">
              <div className="p-6 lg:py-8 lg:px-[107px] flex flex-col gap-6 lg:gap-4 md:w-1/2 w-full overflow-auto">
                {menuMainLinks?.length && (
                  menuMainLinks.map((item) => (
                    <Link key={item.id} href={item.url}
                          className='text-xl lg:text-[40px] leading-tight lg:leading-normal text-foreground-dark hover:text-brand-mer-500 transition'>
                      {item.title}
                    </Link>
                  ))
                )}
              </div>
              {
                isMobileMenu ? (
                  <div className="w-1/2">
                    <AudioControlsBig/>
                  </div>
                ) : (
                  <div>
                    <div className="px-6 py-8 border-t border-b border-neutral-300 bg-neutral-100 flex flex-col gap-6">
                      <MeteoWidget/>
                    </div>

                    <div className="px-6 py-8 flex items-center gap-3">
                      <SocialLinkFb
                        className='rounded-full transition-all bg-brand-mer-500 hover:bg-brand-mer-200 lg:hover:bg-brand-mer-500 lg:bg-neutral-400 w-11 h-11 lg:w-8 lg:h-8 grid place-items-center'
                        iconClassName='w-5 lg:w-4' iconColor='white'/>
                      <SocialLinkX
                        className='rounded-full transition-all bg-brand-mer-500 hover:bg-brand-mer-200 lg:hover:bg-brand-mer-500 lg:bg-neutral-400 w-11 h-11 lg:w-8 lg:h-8 grid place-items-center'
                        iconClassName='w-5 lg:w-4' iconColor='white'/>
                      <SocialLinkYt
                        className='rounded-full transition-all bg-brand-mer-500 hover:bg-brand-mer-200 lg:hover:bg-brand-mer-500 lg:bg-neutral-400 w-11 h-11 lg:w-8 lg:h-8 grid place-items-center'
                        iconClassName='w-5 lg:w-4' iconColor='white'/>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </>
  )
}
